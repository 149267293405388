import React from "react";

const TextImage = ({ image, title, description }) => {
  return (
    <div className="section-text-image-holder">
      <div className="section-text-image-content">
        <div className="section-text-image-image">
          <img src={image} className="img-fluid" alt="Fuka" />
        </div>
        <div className="section-text-image-text">
          <div className="section-text-image-holder-information">
            <h4>{title}</h4>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextImage;
