import React from "react";
import { Link } from "react-router-dom";

const Hero = (props) => {
  const { image, title, subtitle, link, linkName } = props.data || {};

  return (
    <div className="hero-holder-content">
      <div className="hero-content-main">
        <div className="hero-text-box">
          <h1>{title}</h1>
          {subtitle && <h2>{subtitle}</h2>}
          {linkName && (
            <h3>
              <Link to={link}>{linkName}</Link>
            </h3>
          )}
        </div>
        <div className="hero-image-box">
          {image && (
            <img src={"/images/" + image} className="img-fluid" alt="Fuka" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
