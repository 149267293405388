import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataCartInfo: [],
  dataCartItemsInfo: [],
  dataPagesListBlogAll: [],
  dateCategoriesList: [],
  dataProductsList: [],
  dataProductsList1: [],
  dataProductsList2: [],
  dataRelatedProductsList: [],
  dataProductsMesecnaAkcijaList: [],
  dataProductsPopularnoList: [],
  dataProductsPreporucujemoList: [],
  dataProductsPonovoNaStanjuList: [],
  dataProductsIzdvajamoIzPonudeList: [],
  dataProductsSpecijalnaAkcijaList: [],
  dataSearchProductsList: [],
  dataCatalogPaginationList: [],
  dataCatalogFiltersList: [],
  dataClientListAll: [],
  dataSliderListAll: [],
  dataBannerListAll: [],
  dataProductDetailList: [],
  dataCatalogList: [],
  loading: false,
  loadingCatalog: false,
  loadingProductDetail: false,
  loadingSearch: false,
  error: undefined,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
    actionStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    actionStartCatalog(state) {
      state.loadingCatalog = true;
      state.error = undefined;
    },
    actionStartCatalog2(state) {
      state.dataProductsList = [];
      state.loadingCatalog = true;
      state.error = undefined;
    },
    actionStartProductDetail(state) {
      state.loadingProductDetail = true;
      state.error = undefined;
    },
    actionStartSearch(state) {
      state.loadingSearch = true;
      state.error = undefined;
    },
    fetchItemsListBlogAll(state, action) {
      state.dataPagesListBlogAll = action.payload;
      state.loading = false;
    },
    fetchItemsCategoriesAll(state, action) {
      state.dateCategoriesList = action.payload;
      state.loading = false;
    },
    fetchProductsAll(state, action) {
      state.dataProductsList = action.payload;
      state.loading = false;
    },
    fetchProductsAll1(state, action) {
      state.dataProductsList1 = action.payload;
      state.loading = false;
    },
    fetchProductsAll2(state, action) {
      state.dataProductsList2 = action.payload;
      state.loading = false;
    },
    fetchDetailProduct(state, action) {
      state.dataProductDetailList = action.payload;
      state.loading = false;
      state.loadingProductDetail = false;
    },
    fetchRelatedProductsAll(state, action) {
      state.dataRelatedProductsList = action.payload;
      state.loading = false;
    },
    fetchProductsMesecnaAkcija(state, action) {
      state.dataProductsMesecnaAkcijaList = action.payload;
      state.loading = false;
    },
    fetchProductsPopularno(state, action) {
      state.dataProductsPopularnoList = action.payload;
      state.loading = false;
    },
    fetchProductsPreporucujemo(state, action) {
      state.dataProductsPreporucujemoList = action.payload;
      state.loading = false;
    },
    fetchProductsPonovoNaStanju(state, action) {
      state.dataProductsPonovoNaStanjuList = action.payload;
      state.loading = false;
    },
    fetchProductsIzdvajamoIzPonude(state, action) {
      state.dataProductsIzdvajamoIzPonudeList = action.payload;
      state.loading = false;
    },
    fetchProductsSpecijalnaAkcija(state, action) {
      state.dataProductsSpecijalnaAkcijaList = action.payload;
      state.loading = false;
    },
    dataSearchProductsList(state, action) {
      state.dataSearchProductsList = action.payload;
      state.loading = false;
      state.loadingSearch = false;
    },
    dataCatalogPagination(state, action) {
      state.dataCatalogPaginationList = action.payload;
      state.loading = false;
    },
    dataCatalogFilters(state, action) {
      state.dataCatalogFiltersList = action.payload;
      state.loadingCatalog = false;
    },
    dataClientList(state, action) {
      state.dataClientListAll = action.payload;
      state.loading = false;
    },
    dataSliderList(state, action) {
      state.dataSliderListAll = action.payload;
      state.loading = false;
    },
    dataBannerList(state, action) {
      state.dataBannerListAll = action.payload;
      state.loading = false;
    },
    dataCatalogList(state, action) {
      state.dataCatalogList = action.payload;
      state.loading = false;
    },
    dataCartInfo(state, action) {
      state.dataCartInfo = action.payload;
      state.loading = false;
    },
    dataCartItemsInfo(state, action) {
      state.dataCartItemsInfo = action.payload;
      state.loading = false;
    },
  },
});

export const globalActions = globalSlice.actions;

export default globalSlice.reducer;
