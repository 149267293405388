import React from "react";
import ProductItem from "./ProductItem";

const RelatedProducts = () => {
  const products = [
    {
      image: "/images/product1.jpg",
      category: "Coffee",
      title: "Colombia El Mirador Pink Bourbon - Signature",
      id: Math.floor(Math.random() * 100000000) + 1,
    },
    {
      image: "/images/product1.jpg",
      category: "Coffee",
      title: "Burundi Masha Natural",
      id: Math.floor(Math.random() * 100000000) + 1,
    },
    {
      image: "/images/product1.jpg",
      category: "Coffee",
      title: "Colombia Tumbaga Sugarcane Decaf",
      id: Math.floor(Math.random() * 100000000) + 1,
    },
    {
      image: "/images/product1.jpg",
      category: "Coffee",
      title: "Ethiopia Guji Tero Organic",
      id: Math.floor(Math.random() * 100000000) + 1,
    },
  ];

  return (
    <div className="related-products-section-holder">
      <div className="section-title">
        <h2>Related Products.</h2>
      </div>
      <div className="related-products-section-content">
        <div className="products-content-main">
          {products?.map((item, key) => (
            <ProductItem item={item} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedProducts;
