import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberInput from "./Reusable/NumberInput";
import React from "react";
import { useDispatch } from "react-redux";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { formattedPrice } from "../utils";
import { fetchRemoveItemFromCart } from "../actions/global";
import i18next from "i18next";

const CartPageItem = ({ item }) => {
  const dispatch = useDispatch();

  const removeItemFromCart = (item) => {
    dispatch(fetchRemoveItemFromCart(item.id_order, item.id, i18next.language));
  };

  return (
    <tr>
      <td className="image-content-cart-item-name">
        <div className="image-content-cart-item-name-holder">
          <img src={item?.image} className="img-fluid" alt="Fuka" />
          <h5>{item?.name}</h5>
        </div>
      </td>
      <td className="text-end mobile-width-column">
        <div className="mobile-width-column">
          {formattedPrice(item?.price)} RSD
        </div>
      </td>
      <td className="text-end">{item?.qty}</td>
      <td className="table-cart-item-subtotal">
        <div className="mobile-width-column">
          {formattedPrice(item?.total_price)} RSD
        </div>
      </td>
      <td
        className="table-cart-item-remove"
        onClick={() => removeItemFromCart(item)}
      >
        <FontAwesomeIcon icon={faClose} />
      </td>
    </tr>
  );
};

export default CartPageItem;
