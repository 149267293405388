import React from "react";
import Hero from "../components/Hero";
import ContactFooter from "../components/ContactFooter";
import TextImage from "../components/TextImage";
import ImageText from "../components/ImageText";
import AboutInfoSection from "../components/AboutInfoSection";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const heroData = {
    image: "hero-about.png",
    title: t("about.title"),
    subtitle: t("about.subtitle"),
    link: "/catalog/coffee",
    linkName: t("about.linkText"),
  };

  return (
    <div className="about-holder-content">
      <Hero data={heroData} />
      <TextImage
        image={"/images/gallery4.jpg"}
        title={t("about.section_1.title")}
        description={t("about.section_1.description")}
      />
      <ImageText
        image={"/images/gallery5.jpg"}
        title={t("about.section_2.title")}
        description={t("about.section_2.description")}
      />
      <AboutInfoSection t={t} />
      <TextImage
        image={"/images/gallery3.jpg"}
        title={t("about.section_4.title")}
        description={t("about.section_4.description")}
      />
      <ContactFooter />
    </div>
  );
};

export default About;
