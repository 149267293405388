import { TextField, Tooltip } from "@mui/material";
import { isUndefined } from "lodash";
import React, { Fragment, useRef } from "react";

const InputField = (props) => {
  const { tooltipText } = props || {};
  const refInput = useRef();

  return (
    <Fragment>
      {tooltipText ? (
        <TooltipTextField propsInput={props} refInput={refInput} />
      ) : (
        <BasicTextField propsInput={props} refInput={refInput} />
      )}
    </Fragment>
  );
};

const TooltipTextField = (props) => {
  const { propsInput, refInput } = props || {};

  return (
    <Tooltip title={propsInput.tooltipText} arrow={true} disableInteractive>
      <div>
        <BasicTextField propsInput={propsInput} />
      </div>
    </Tooltip>
  );
};

const BasicTextField = (props) => {
  const { propsInput, refInput } = props || {};

  const {
    label,
    name,
    className,
    value,
    onChange,
    onKeyDown,
    min,
    required,
    disabled,
    multiline = false,
    rows,
    size,
    tooltipText,
    defaultValue,
    inputProps,
    inputLabelProps = {},
    error,
    type = "input",
  } = propsInput || {};

  const inputValue = value === null ? "" : value;
  const errorMessage = typeof error !== "string" ? error?.[name] : error;
  const hasError = !isUndefined(errorMessage);

  return (
    <TextField
      label={label}
      inputRef={refInput}
      name={name}
      defaultValue={defaultValue}
      rows={rows}
      multiline={multiline}
      error={hasError}
      className={`${className}`}
      helperText={errorMessage}
      value={inputValue}
      min={min}
      onChange={(event) => {
        onChange?.(event);
      }}
      onKeyDown={(event) => {
        onKeyDown?.(event);
      }}
      disabled={!!disabled}
      required={required}
      fullWidth
      size={size}
      type={type}
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
    />
  );
};

export default InputField;
