import React from "react";
import Hero from "./Hero";
import { useTranslation } from "react-i18next";

const ContactFooter = () => {
  const { t } = useTranslation();

  const heroData = {
    image: "contact-footer.png",
    title: t("other.footer_contact_title"),
    link: "/contact",
    linkName: t("other.footer_contact_text"),
  };
  return (
    <div className="footer-contact-section-holder">
      <Hero data={heroData} />
    </div>
  );
};

export default ContactFooter;
