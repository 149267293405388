import React from "react";
import ContactFooter from "../components/ContactFooter";
import Hero from "../components/Hero";
import { useTranslation } from "react-i18next";

const SuccessPage = () => {
  const { t } = useTranslation();
  const heroData = {
    image: "hero-home.png",
    title: t("success_page.title"),
    subtitle: t("success_page.subtitle"),
    link: "/",
    linkName: t("success_page.linkName"),
  };

  return (
    <div className="home-holder-content">
      <Hero data={heroData} />
      <ContactFooter />
    </div>
  );
};

export default SuccessPage;
