import { toast } from "react-toastify";
import {
  sentContactFormPath,
  getBlogAllListItems,
  getCategories,
  getProducts,
  getProductsCategoriesType,
  getSearchProducts,
  getClientList,
  getPageNumberTotal,
  getProductsWithPagination,
  getCatalogFilters,
  sentNewsletterFormPath,
  getSliderList,
  getBannerList,
  getCatalogList,
  createAddItemToCart,
  removeItemFromCart,
  getItemsFromCart,
  updateCartFinish,
  sendContactForm,
} from "../constants/apiRoutes";
import { globalActions } from "../reducers/globalActions";
import apiService from "../utils/configAxios";
import i18next from "i18next";

export const fetchContactFormSent = (
  dataValues,
  showMessageContact,
  navigate
) => {
  return (dispatch) => {
    return apiService
      .post(sentContactFormPath(), dataValues)
      .then((response) => {
        showMessageContact();
        console.log(response, "respo");
      })
      .catch((err) => {
        navigate("", { replace: true });
        console.log(err, "err");
      });
  };
};

export const fetchNewsletterFormSent = (dataValues, showMessageContact) => {
  return (dispatch) => {
    return apiService
      .post(sentNewsletterFormPath(), dataValues)
      .then((response) => {
        showMessageContact();
        console.log(response, "respo");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchBlogListItems = (id = 0) => {
  return (dispatch) => {
    return apiService
      .get(getBlogAllListItems(i18next.language, id))
      .then((response) => {
        dispatch(globalActions.fetchItemsListBlogAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCategoriesList = () => {
  return (dispatch) => {
    return apiService
      .get(getCategories())
      .then((response) => {
        dispatch(globalActions.fetchItemsCategoriesAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsHomeCategoryAll1 = (dataValue) => {
  return (dispatch) => {
    return apiService
      .post(getProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.fetchProductsAll1(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsHomeCategoryAll2 = (dataValue) => {
  return (dispatch) => {
    return apiService
      .post(getProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.fetchProductsAll2(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchRelatedProductsAll = (dataValue) => {
  return (dispatch) => {
    return apiService
      .post(getProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.fetchRelatedProductsAll(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsDetail = (id, id_language) => {
  const languageId = id_language == "sr" ? 1 : 2;
  return (dispatch) => {
    dispatch(globalActions.actionStartProductDetail());
    return apiService
      .get(getProducts(id, languageId))
      .then((response) => {
        dispatch(globalActions.fetchDetailProduct(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsAllWithPagination = (dataValue) => {
  return (dispatch) => {
    dispatch(globalActions.actionStartCatalog2());
    return apiService
      .post(getProductsWithPagination(), dataValue)
      .then((response) => {
        dispatch(globalActions.fetchProductsAll(response.data.items));
        // dispatch(globalActions.dataCatalogFilters(response.data.filters));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchSearchProductsAll = (dataValue) => {
  return (dispatch) => {
    dispatch(globalActions.actionStartSearch());
    return apiService
      .post(getSearchProducts(), dataValue)
      .then((response) => {
        dispatch(globalActions.dataSearchProductsList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCilentsList = () => {
  return (dispatch) => {
    return apiService
      .get(getClientList())
      .then((response) => {
        dispatch(globalActions.dataClientList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchSlidersList = () => {
  return (dispatch) => {
    return apiService
      .get(getSliderList())
      .then((response) => {
        dispatch(globalActions.dataSliderList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchBannersList = () => {
  return (dispatch) => {
    return apiService
      .get(getBannerList())
      .then((response) => {
        dispatch(globalActions.dataBannerList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCatalogList = (id, id_language) => {
  const languageId = id_language == "sr" ? 1 : 2;

  return (dispatch) => {
    return apiService
      .get(getCatalogList(id, languageId))
      .then((response) => {
        dispatch(globalActions.dataCatalogList(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchPageNumberTotal = (dataValue) => {
  return (dispatch) => {
    return apiService
      .post(getPageNumberTotal(), dataValue)
      .then((response) => {
        dispatch(globalActions.dataCatalogPagination(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCartFinish = (dataValue, navigate) => {
  return (dispatch) => {
    return apiService
      .post(updateCartFinish(), dataValue)
      .then((response) => {
        if (response.data.is_success == 1) {
          //potrebno je ocistiti sesiju i reducer za order i order_items
          dispatch(globalActions.dataCartInfo([]));
          dispatch(globalActions.dataCartItemsInfo([]));
          localStorage.removeItem("cart");
          navigate("/success-page");
        }
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error(err, { theme: "colored" });
      });
  };
};

export const sendContactFormFunction = (
  dataValue,
  t,
  resetForm,
  resetCaptcha
) => {
  return (dispatch) => {
    return apiService
      .post(sendContactForm(), dataValue)
      .then((response) => {
        console.log(response.data);
        toast.success(t("other.send_contact_form_message"), {
          theme: "colored",
        });
        resetForm();
        resetCaptcha();
      })
      .catch((err) => {
        toast.success(t("other.send_contact_form_message_error"), {
          theme: "colored",
        });
        resetCaptcha();
      });
  };
};

//dodavanje stavke u korpu / kreiranje korpe ako nema order_id
export const fetchAddProductToCart = (dataValue, resetCaptcha) => {
  return (dispatch) => {
    return apiService
      .post(createAddItemToCart(), dataValue)
      .then((response) => {
        dispatch(globalActions.dataCartInfo(response.data.order));
        // dispatch(globalActions.dataCartItemsInfo(response.data.order_items));
        localStorage.setItem("cart", JSON.stringify(response.data.order));
        resetCaptcha();
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        resetCaptcha();
        toast.error(err.message, { theme: "colored" });
      });
  };
};

//dohvatanje stavki za korpu
export const fetchGetItemsFromCart = (id, id_language) => {
  const languageId = id_language == "sr" ? 1 : 2;
  return (dispatch) => {
    return apiService
      .get(getItemsFromCart(id, languageId))
      .then((response) => {
        //detalji korpe osvezavam
        dispatch(globalActions.dataCartInfo(response.data.order));
        localStorage.setItem("cart", JSON.stringify(response.data.order));

        //dohvatam stavke za korpu
        dispatch(globalActions.dataCartItemsInfo(response.data.order_items));
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

//brisanje stavke iz korpu
export const fetchRemoveItemFromCart = (id_order, id_order_item, language) => {
  const languageId = language == "sr" ? 1 : 2;
  return (dispatch) => {
    return apiService
      .delete(removeItemFromCart(id_order, id_order_item, languageId))
      .then((response) => {
        //osvezavanje korpe
        dispatch(globalActions.dataCartInfo(response.data.order));
        localStorage.setItem("cart", JSON.stringify(response.data.order));

        //osvezavanje stavki u korpi
        dispatch(globalActions.dataCartItemsInfo(response.data.order_items));

        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchCatalogFilters = (dataValue) => {
  return (dispatch) => {
    dispatch(globalActions.actionStartCatalog());
    return apiService
      .post(getCatalogFilters(), dataValue)
      .then((response) => {
        dispatch(globalActions.dataCatalogFilters(response.data));
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};

export const fetchProductsCategoriesTypeAll = (type) => {
  return (dispatch) => {
    return apiService
      .get(getProductsCategoriesType(type))
      .then((response) => {
        if (type === "mesecnaAkcija") {
          dispatch(globalActions.fetchProductsMesecnaAkcija(response.data));
        } else if (type === "popularno") {
          dispatch(globalActions.fetchProductsPopularno(response.data));
        } else if (type === "izdvajamo") {
          dispatch(globalActions.fetchProductsIzdvajamoIzPonude(response.data));
        } else if (type === "preporucujemo") {
          dispatch(globalActions.fetchProductsPreporucujemo(response.data));
        } else if (type === "specijalnaAkcija") {
          dispatch(globalActions.fetchProductsSpecijalnaAkcija(response.data));
        } else if (type === "ponovoNaStanju") {
          dispatch(globalActions.fetchProductsPonovoNaStanju(response.data));
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
};
