import React from "react";

const ImageText = ({ image, title, description }) => {
  return (
    <div className="section-image-text-holder">
      <div className="section-image-text-content">
        <div className="section-image-text-text">
          <div className="section-image-text-holder">
            <h4>{title}</h4>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
        <div className="section-image-text-image">
          <img src={image} className="img-fluid" alt="Fuka" />
        </div>
      </div>
    </div>
  );
};

export default ImageText;
