import React, { Fragment, useEffect, useState } from "react";
import Hero from "../components/Hero";
import ContactFooter from "../components/ContactFooter";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import PaymentOptions from "../components/PaymentOptions";
import { Link, useNavigate } from "react-router-dom";
import CheckoutProductItem from "../components/CheckoutProductItem";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import { fetchCartFinish, fetchGetItemsFromCart } from "../actions/global";
import { basicFormValidationHandler, formattedPrice } from "../utils";
import InputField from "../components/Reusable/InputField";

import { isEmpty, isUndefined } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const { t } = useTranslation();
  const heroData = {
    title: t("pages.checkout_title"),
  };
  const dispatch = useDispatch();

  const { dataCartInfo, dataCartItemsInfo } = useSelector(
    (state) => state.globalReducer
  );

  useEffect(() => {
    if (dataCartInfo.id) {
      dispatch(fetchGetItemsFromCart(dataCartInfo.id, i18next.language));
    }
  }, []);

  const initialState = {
    country: "Serbia",
    email: "",
    first_name: "",
    last_name: "",
    company: "",
    address: "",
    postal_code: "",
    apartment: "",
    city: "",
    phone: "",
    note: "",
    payment_method: "",
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event?.target || {};

    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleInputChangePaymentMethod = (value) => {
    setData((prevUser) => ({
      ...prevUser,
      payment_method: value,
    }));
  };

  const clientValidationHandler = () => {
    const { first_name, last_name, email, address, postal_code, city, phone } =
      data;
    const errorsObject = basicFormValidationHandler(
      {
        first_name,
        last_name,
        email,
        address,
        postal_code,
        city,
        phone,
      },
      t
    );

    setErrors(errorsObject);

    if (!isEmpty(errorsObject)) {
      toast.error(t("other.required_form_submit"), { theme: "colored" });
    }

    return !isEmpty(errorsObject); // Ako ima grešaka, vraća `true`
  };

  const handleCheckout = (event) => {
    event.preventDefault();

    if (!isTermsAccepted) {
      toast.error(t("other.terms_accept_form_submit"), {
        theme: "colored",
      });
      return;
    }

    if (clientValidationHandler()) {
      return;
    }

    data.id_order = dataCartInfo.id;
    dispatch(fetchCartFinish(data, navigate));
  };

  const handleTermsChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };

  return (
    <div className="checkout-holder-content">
      <div className="checkout-hero-holder">
        <Hero data={heroData} />
      </div>

      <div className="checkout-content-main">
        <div className="checkout-content-form-holder">
          <Paper elevation={1}>
            <Container fluid>
              <div className="checkout-content-form">
                <div className="checkout-title-info">
                  <h5>{t("payment_page.form.title_1")}</h5>
                </div>
                <div className="checkout-content-form-inputs">
                  <Row>
                    <Col md={12}>
                      <InputField
                        id="outlined-basic"
                        label={t("payment_page.form.email")}
                        className="w-100"
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </Col>
                  </Row>
                </div>
                <div className="checkout-title-info-section">
                  <h5>{t("payment_page.form.title_2")}</h5>
                </div>
                <div className="checkout-content-form-inputs">
                  <Row>
                    <Col md={6}>
                      <InputField
                        id="outlined-basic"
                        label={t("payment_page.form.first_name")}
                        className="w-100"
                        error={errors}
                        errorsHandler={setErrors}
                        required
                        type="text"
                        name="first_name"
                        value={data.first_name}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col md={6} className="margin-top-mobile-4 mt-md-0">
                      <InputField
                        id="outlined-basic"
                        label={t("payment_page.form.last_name")}
                        className="w-100"
                        name="last_name"
                        value={data.last_name}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </Col>
                    <Col md={12} className="mt-4">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("payment_page.form.country")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={data.country}
                          label={t("payment_page.form.country")}
                          name="country"
                          onChange={handleInputChange}
                        >
                          <MenuItem value={"Serbia"}>Serbia</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col md={6} className="mt-4">
                      <InputField
                        id="outlined-basic"
                        label={t("payment_page.form.company")}
                        className="w-100"
                        name="company"
                        value={data.company}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col md={6} className="mt-4">
                      <InputField
                        id="outlined-basic"
                        label={t("payment_page.form.address")}
                        className="w-100"
                        name="address"
                        value={data.address}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </Col>
                    <Col md={6} className="mt-4">
                      <InputField
                        label={t("payment_page.form.apartment")}
                        className="w-100"
                        name="apartment"
                        value={data.apartment}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col md={6} className="mt-4">
                      <InputField
                        label={t("payment_page.form.postal_code")}
                        className="w-100"
                        name="postal_code"
                        value={data.postal_code}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </Col>
                    <Col md={6} className="mt-4">
                      <InputField
                        label={t("payment_page.form.city")}
                        className="w-100"
                        name="city"
                        value={data.city}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </Col>
                    <Col md={6} className="mt-4">
                      <InputField
                        label={t("payment_page.form.phone")}
                        className="w-100"
                        type="number"
                        name="phone"
                        value={data.phone}
                        onChange={handleInputChange}
                        error={errors}
                        errorsHandler={setErrors}
                        required
                      />
                    </Col>
                    <Col md={12} className="mt-4">
                      <InputField
                        label={t("payment_page.form.note")}
                        className="w-100"
                        multiline
                        name="note"
                        value={data.note}
                        onChange={handleInputChange}
                        rows={4}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="checkout-title-info-section">
                  <h5>{t("payment_page.form.title_3")}</h5>
                </div>
                <div className="checkout-payment-holder">
                  <PaymentOptions
                    t={t}
                    handleInputChangePaymentMethod={
                      handleInputChangePaymentMethod
                    }
                  />
                </div>
              </div>
              <Row>
                <Col md={12}>
                  <div className="checkout-button-holder">
                    <div className="checout-button-info-policy">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isTermsAccepted}
                            onChange={handleTermsChange}
                            required
                          />
                        }
                        label={
                          <Fragment>
                            {t("other.terms_accept")}{" "}
                            <Link
                              to="/terms-and-conditions"
                              style={{
                                textDecoration: "none",
                                color: "#1976d2",
                              }}
                            >
                              terms and conditions.
                            </Link>{" "}
                          </Fragment>
                        }
                      />
                    </div>
                    <button
                      className="button-custom-black w-100"
                      onClick={handleCheckout}
                    >
                      {t("payment_page.proceed_to_checkout")}
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Paper>
        </div>
        <div className="checkout-content-information-items">
          <Paper elevation={1}>
            <div className="checkout-content-main-holder">
              <h3>{t("payment_page.summary")}.</h3>
              <div className="checkout-information-summary">
                <div className="checkout-information-summary-item">
                  <div className="checkout-information-summary-item-title">
                    {t("payment_page.subtotal")}
                  </div>
                  <div className="checkout-information-summary-item-sum">
                    {formattedPrice(dataCartInfo?.total_price_sum)}{" "}
                    <small>RSD</small>
                  </div>
                </div>
                <div className="checkout-information-summary-item">
                  <div className="checkout-information-summary-item-title">
                    {t("payment_page.shipping")}
                  </div>
                  <div className="checkout-information-summary-item-sum">
                    0,00 <small>RSD</small>
                  </div>
                </div>
                <div className="checkout-information-summary-total">
                  <div className="checkout-information-summary-item-title">
                    {t("payment_page.total")}
                  </div>
                  <div className="checkout-information-summary-item-sum">
                    {formattedPrice(dataCartInfo?.total_price_sum)}{" "}
                    <small>RSD</small>
                  </div>
                </div>
              </div>
              <div className="checkout-information-list-items">
                {dataCartItemsInfo?.map((item, key) => (
                  <CheckoutProductItem item={item} key={key} />
                ))}
              </div>
            </div>
          </Paper>
        </div>
      </div>

      <ContactFooter />
    </div>
  );
};

export default Checkout;
